



































    import {Component, Prop, Vue} from "vue-property-decorator";
    import {SoldItem} from "../../../interfaces/entities/SoldItem";

@Component({})
export default class CardContent extends Vue {
    @Prop({required: true}) type!: string;
    @Prop({required: true}) item!: any;
}
