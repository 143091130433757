








































































































import { Component, Prop, Vue } from "vue-property-decorator";
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";
import TableList from "@/components/partials/TableList.vue";
import TDSDate from "@/util/TDSDate";
import TDSButton from "@/components/common/TDSButton.vue";
import HistoryItemCardContent from "@/components/partials/Account/HistoryItemCardContent.vue";
import { PurchaseHistoryEntry, PurchaseHistoryEntryItem } from "@/interfaces/entities/PurchaseHistoryEntry";
import TDSSpinner from "@/components/common/TDSSpinner.vue";
import { tools } from "@/util/tools";
import { BulkDiscount } from "@/interfaces/entities/BulkDiscount";

@Component({
    components: { TDSSpinner, HistoryItemCardContent, TDSButton, TableList, DefaultLayout }
})
export default class AccountHistory extends Vue {
    @Prop({ type: String, default: "" }) itemId!: string;

    private tableColumnsHistory: object = {
        date: {
            label: this.$t("tableHeader.date") as string,
            width: "160px",
            sortable: true
        },
        purchased: {
            label: this.$t("tableHeader.purchased") as string,
            sortable: true,
            sortBy: (item: PurchaseHistoryEntry) => item.totalVolume.volumeSize
        },
        paid: {
            label: this.$t("tableHeader.paid") as string,
            sortable: true,
            sortBy: (item: PurchaseHistoryEntry) => item.totalPrices[0].priceValue
        }
    };

    private purchaseHistory: Array<PurchaseHistoryEntry> = [];
    private isLoading: boolean = false;

    get isMobile(): boolean {
        return this.$store.state.isMobile;
    }

    created() {
        document.title = process.env.VUE_APP_THEME_TITLE + " | " + this.$t("account.history.pageTitle");
        this.fetchData();
    }

    private async fetchData() {
        this.isLoading = true;
        try {
            this.purchaseHistory = (await this.$store.dispatch("FETCH_PURCHASE_HISTORY"))
                .map(
                    (historyEntry: PurchaseHistoryEntry): PurchaseHistoryEntry => {
                        historyEntry.bulkDiscounts = historyEntry.bulkDiscounts.filter((item: BulkDiscount) => item.status !== "FAILURE" && item.status !== "PENDING");
                        historyEntry.items = historyEntry.items.filter((item: PurchaseHistoryEntryItem) => item.status !== "FAILURE");
                        historyEntry.hasPending = Boolean(historyEntry.items.find((item: PurchaseHistoryEntryItem) => item.status === "PENDING"));
                        return historyEntry;
                    }
                )
                .filter((item: PurchaseHistoryEntry) => item.items.length > 0)
                .reverse();
        } catch (e) {
            console.error("[Account Purchase History] Failed fetching history items");
        } finally {
            this.isLoading = false;
        }
    }

    private showDetails(id: string) {
        if (!id) return;
        this.$router.push("/account/purchase-history/" + id);
    }

    private async downloadReceipt(url: string) {
        if (!url) return;
        try {
            this.$store.dispatch("FETCH_RECEIPT", url);
        } catch (e) {
            console.error(e);
            this.$toastr.error("Failed downloading receipt. Please try again later.");
        }
    }
}
