import { render, staticRenderFns } from "./HistoryDetails.vue?vue&type=template&id=14230bf7&scoped=true&"
import script from "./HistoryDetails.vue?vue&type=script&lang=ts&"
export * from "./HistoryDetails.vue?vue&type=script&lang=ts&"
import style0 from "./HistoryDetails.vue?vue&type=style&index=0&id=14230bf7&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14230bf7",
  null
  
)

export default component.exports