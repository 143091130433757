import Vue, {VNode, VueConstructor} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import VueGtag from "vue-gtag";
import {ImagePath} from "./plugins/ImagePath";
import {Cart} from "./plugins/Cart";
import {Inventory} from "./plugins/Inventory";
import {Toastr} from "./plugins/Toastr/Toastr";
import {Authenticator} from "./plugins/Authenticator";
import {Http} from "@/plugins/Http";
import {LocalStorage} from "@/plugins/LocalStorage";
import {authenticator} from "@/util/authenticator";
import VueScrollTo from "vue-scrollto";
import TDSDate from "@/util/TDSDate";
import {Regexes} from "@/plugins/Regexes";
import Countries from "@/util/countries";

require("dotenv").config();

Vue.config.productionTip = false;

Vue.use(ImagePath);
Vue.use(Cart);
Vue.use(Inventory);
Vue.use(Toastr);
Vue.use(Authenticator);
Vue.use(Http);
Vue.use(LocalStorage);
Vue.use(VueScrollTo);
Vue.use(Regexes);
Vue.use(VueGtag, {
    config: { id: process.env.VUE_APP_ANALYTICS_ID }
});

// TODO: Get currency info from API and make format based on that...

Vue.filter("cryptoFormat", (value: number | string, maximumFractionDigits: number, code: string) => {
    if (typeof value === "string") value = parseFloat(value);
    return new Intl
        .NumberFormat("en", {maximumFractionDigits})
        .format(value) + " " + code;
});

Vue.filter("fiatFormat", (value: number | string, showCurrency: boolean = true) => {
    if (typeof value === "string") value = parseFloat(value);
    if(value >= 0) {
        return new Intl.NumberFormat("en", {
            style: showCurrency ? "currency" : "decimal",
            currency: "USD",
            currencyDisplay: "code",
            minimumFractionDigits: 2
        }).format(value);
    } else {
        const formatParts = new Intl.NumberFormat("en", {
            style: showCurrency ? "currency" : "decimal",
            currency: "USD",
            currencyDisplay: "code",
            minimumFractionDigits: 2
        }).formatToParts(value * (-1));
        return formatParts.reduce((acc: string, curr: {type: string; value: string}) => acc + (curr.type === "literal" ? curr.value + "-" : curr.value), "");
    }
});

Vue.filter("compactFiatFormat", (value: number | string, currencySign: boolean = false) => {
    if (typeof value === "string") value = parseFloat(value);
    if(value >= 0) {
        return new Intl.NumberFormat("en", {
            style: "currency",
            currency: "USD",
            currencyDisplay: currencySign ? "symbol" : "code",
            //@ts-ignore - typescript is not supporting the notation for numberFormat yet
            notation: "compact"
        }).format(value);
    } else {
        const formatParts = new Intl.NumberFormat("en", {
            style: "currency",
            currency: "USD",
            currencyDisplay: currencySign ? "symbol" : "code",
            //@ts-ignore - typescript is not supporting the notation for numberFormat yet
            notation: "compact"
        }).formatToParts(value * (-1));
        return formatParts.reduce((acc: string, curr: {type: string; value: string}) => acc + (curr.type === "literal" ? curr.value + "-" : curr.value), "");
    }
});


Vue.filter("percentageFormat", (value: number | string) => {
    if (typeof value === "string") value = Number(value);
    return (value > 0 ? "+" : "") + value.toFixed(2) + "%";
});

Vue.filter("toPercent", (value: number | string) => {
    if (typeof value === "string") value = Number(value);
    return value * 100 + "%";
});

Vue.filter("timeFormat", (value: number | string, format: string) => {
    if (typeof value !== "number") return value;
    switch (format) {
        case "d": // @ts-ignore
            return Math.round(value / 60 / 24) + " " + i18n.tc("general.timeAppendices.d", Math.round(value / 60 / 24));
        default:
            return value;
    }
});

Vue.filter("dateFormat", (value: number | string, format: string) => {
    if (typeof value !== "string") return value;
    // @ts-ignore
    return new TDSDate(value).format(format || "DD-MM-YYYY HH:mm");
});

Vue.filter("countryName", (value: string) => {
    if(value.toLowerCase() === "www") return "Worldwide";
    return Countries.getCountryName(value);
});

//  In an older version we got some service workers (empty) registered that broke the cache on Safari.
//  So we are removing all service workers here.
if ("serviceWorker" in navigator) {
    navigator.serviceWorker.getRegistrations().then(function (registrations: readonly ServiceWorkerRegistration[]) {
        if (registrations.length > 0) {
            console.log("[main] Service worker registrations: ", registrations);
            for (const registration of registrations) {
                registration.unregister().then(() => {
                    console.log("[main] Unregistered service worker.", registration);
                });
            }
        }
    });
} else {
    console.warn("[main] Browser does not support service workers!");
}

async function init() {
    try {
        const accessToken: string | undefined = await authenticator.getAccessToken();
        console.log("[main] Got access token: ", accessToken);
        store.commit("SET_AUTH", accessToken ?? "");
        if (accessToken) {
            await store.dispatch("FETCH_ACCOUNT");
        } else {
            window.localStorage.clear();
        }
    } catch (err) {
        window.localStorage.clear();
        store.commit("SET_AUTH", "");
        await authenticator.signOut();
        console.error("[App] Unexpected error on getting access token: ", err);
    }

    new Vue({
        router,
        i18n,
        store,
        render: (h: (component: VueConstructor) => VNode) => h(App)
    }).$mount("#app");
}

init().then(() => console.log("[main] Application started."));
