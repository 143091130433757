
































import { Component, Vue, Watch } from "vue-property-decorator";

// @ts-ignore
import { SimpleSVG } from "vue-simple-svg";
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";
import Details from "@/components/partials/Account/Details.vue";
import History from "@/components/partials/Account/History.vue";
import { User } from "@/interfaces/entities/User";
import { Address, AddressType } from "@/interfaces/entities/Address";

@Component({
    components: { History, Details, DefaultLayout, SimpleSVG }
})
export default class AccountDetails extends Vue {
    private isLoading: boolean = false;
    private userInformations: User = {
        firstName: "",
        lastName: "",
        email: "",
        companyName: "",
        taxId: "",
        addresses: []
    };
    private selectedPage: string = "";
    private itemId: string = "";
    private allowedPages: string[] = ["details", "credit-purchases"];

    get isMobile(): boolean {
        return this.$store.state.isMobile;
    }

    created() {
        this.setPage(this.$route.params.page);
        this.init();
    }

    @Watch("$route.params")
    paramsChanged(newParams: { [key: string]: string }) {
        this.setPage(newParams.page);
    }

    @Watch("isMobile")
    mobileChanged(isMobile: boolean) {
        if (!this.$route.params.page && !isMobile) this.$router.replace("/account/details");
        if (this.$route.params.id && !isMobile) this.$router.replace("/account/credit-purchases");
        if (isMobile && this.$route.params.page) this.$store.commit("SET_PREVIOUS_VIEW", "/account");
        else this.$store.commit("SET_PREVIOUS_VIEW", "/your-store");
    }

    async init() {
        this.isLoading = true;
        try {
            const idToken: string = await this.$authenticator.getIdToken();
            if (!idToken) return;
            this.userInformations = await this.$store.dispatch("FETCH_ACCOUNT_DETAILS");
            if (this.userInformations.addresses !== undefined && this.userInformations.addresses?.length > 0 && this.userInformations.addresses[0].addressType + "" !== "BILLING") {
                const index = this.userInformations.addresses?.findIndex((address: Address) => address.addressType + "" === "BILLING");
                [this.userInformations.addresses[0], this.userInformations.addresses[index]] = [this.userInformations.addresses[index], this.userInformations.addresses[0]];
            }
        } catch (e) {
            this.$toastr.error("Failed loading user information");
        } finally {
            this.isLoading = false;
        }
    }

    private setPage(page: string) {
        if (!page) {
            if (!this.isMobile) this.$router.replace("/account/details");
        } else if (!this.allowedPages.includes(page)) {
            if (!this.isMobile) this.$router.replace("/account/details");
            else this.$router.replace("/account");
        }
        if (this.isMobile) {
            if (this.$route.params.id) this.$store.commit("SET_PREVIOUS_VIEW", "/account/credit-purchases");
            else if (this.allowedPages.includes(page)) this.$store.commit("SET_PREVIOUS_VIEW", "/account");
        } else this.$store.commit("SET_PREVIOUS_VIEW", "/your-store");
        this.selectedPage = this.allowedPages.includes(page) ? page : "";
        this.itemId = this.$route.params.id || "";
    }
}
